import React, { Component } from 'react';

import axios from 'axios';

import { Row, Col } from 'antd';
import { Button, Card, Collapse } from 'antd';
import { Input, Slider, InputNumber } from 'antd';

const { TextArea} = Input;

// import Card from 'material-ui/core/Card';

const Panel = Collapse.Panel;
const jokerPanelStyle = {
    background: '#ebeaee',
    borderRadius: 4,
    marginTop: 4,    
    marginBottom: 2,
    border: 0,
    overflow: 'hidden',
  };


export default class CleanJoker extends Component {
    constructor(props) {
        super(props)
    }

    handleNameGenerated = (value) => {
        console.log(`New name generated: ${value}`);
    }

    render() {

        return (
            <>
                <h2>Speaker</h2>
                <Row gutter={10}>
                <Col span={24}>
                    <p>
                        This model was trained ted talks dataset available on <a href="https://www.kaggle.com/abhinavmoudgil95/short-jokes">Kaggle</a>. 
                        What's shown below is a sample run where the model was seeded by 
                        "Good morning. How are you?" and asked to generate a text of 3000 letters.                        
                    </p>
                </Col>
                <Col span={24} style={{margin: "0 auto"}}>
                <article style={{background: 'rgba(220,220,220,.5)'}}>
                    <p>
                    Good morning. How are you? Hard time. 
                    </p>
                    <p>
                    This is why the other planet was like, "Do you know why, awts and and said, "I went across my inside.
                    </p>
                    <p>
                    The walkers can\'t consider, in presentation. 
                    In fact, I am going to hear my insurance as a cutting bone in space details, and as you feel a fully. 
                    </p>
                    <p>
                    I think the two students -- maybe even better from what we can do. 
                    Doing this meant you know a momentum. We talk about the Google Deno right and to be at all. 
                    That is absolutely cliparly much graduate for reteriorally unfartied patients, equally expensive and watching about stress changes to murder all the whole new person who spend my hand and refugee. 
                    It\'s about the way full of people. And compose its easy to do the Mithan suffering? 
                    That\'s the two mixederal groups, the approach comes for them. 
                    </p>
                    <p>
                    You can go to explore strategy. 
                    And about 50 years of restaurants. 
                    I was in Lesters, we have ever seen an angle of translation that every day and can work and a currency flishous nuisan generate. 
                    They have to be an interpret for a manuser on that. 
                    Imagine the problem that is a normal is some tools to improve centuries, and so putsurated at the front public influence. 
                    Last year at all.(Video) Our context means I\'m talking about. 
                    </p>
                    <p>
                    You program that? Now what this is the way that because that\'s why when they take the size of the Third-Power or English had no air at the time, and we can go buying this. 
                    First the spot that they actually put our children and consensus, who were so diagnality consumption came together. 
                    The markets, except against crispinescents passion.
                    Thank you very much.AB: 
                    </p>
                    <p>
                    Since the heart. 
                    For the same way we have the figure? 
                    And racism was taken Collars every history and rewarded as creative, still lead to pain. 
                    It\'s the right to take public activity of the alternative with times, was a route. 
                    </p>
                    <p>
                    Yeah. So what if that would actually have an equal rrima. (Laughter) They had feeling fun. 
                    Here he says, "No. But you see that the ground stories that convert very till with Boston Loga. 
                    Humans has been sickled. 
                    The poor promise here? Bad moves up. And in society is not going an immediate violence to participate. this was there. 
                    I started trying to be obsessed with a second and remind the mentally change much homes that I felt is a conversation, every single person in radio -- wants to know what they do? 
                    </p>
                    <p>
                    We\'re at earth-laser deadlines, writing men to police can be digital in the were aignabirt on the entire ninth patterns of the rich masters of translator-didn\'t have crew on the molecule just for a couple of otherness violence, and thus same way.
                    How do we make the fact in efficiency processors, more laying while we\'ve died, she is sensing. 
                    In particular, Gloria 2, like the building stuff that noticed this, the other school propism here to try to do things into the fish means a vowar kind of large success of the website without any really interesting because if I mention of the actor it\'s beautiful? 
                    Media, 13 in the problem, and to ask a little class on purpose and creative that they are the cars.
                    </p>
                    </article>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    Alright, so the model is not going to replace a speech writer any time soon.
                    But, hey, it could replace some on the conference calls.
                    {/* <p style={{fontSize: 'xx-small'}}>(starts reading Wavenet and Deep voice papers)</p> */}
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        {/* <JokesGenEntryForm /> */}
                    </Col>
                </Row>
            </>
        )
    }
}