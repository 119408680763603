import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import Img from 'gatsby-image';

import { Row, Col } from 'antd';
import { Button, Card, Collapse, Tag } from 'antd';
import { Input, InputNumber } from 'antd';

// import Card from 'material-ui/core/Card';

const Panel = Collapse.Panel;


const customPanelStyle = {
    background: 'rgba(247,247,247, 0.5)',
    borderRadius: 4,
    marginTop: 4,    
    marginBottom: 2,
    border: 0,
    overflow: 'hidden',
  };

class NameGenEntryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLenValue: 6,
            seedLetter: '',
            generatedName: '',
            characterIcon: {},
            loading: false
        }
    }

    onMaxLenChange = (value) => {
        this.setState({
            maxLenValue: value,
        })
    }

    onSeedLetterChange = (e) => {
        this.setState({
            seedLetter: e.target.value
        })
    }

    generateNewName() {
        // console.log('Generate new name');
        let API = axios.create({
            baseURL: 'https://api.sprinkleai.com'
        });

        let seed = this.state.seedLetter.length > 0 ? this.state.seedLetter : 'a';
        let maxlen = this.state.maxLenValue;
        this.setState({loading: true});
        API.post('/namegen', {
            'model': 'namegen',
            'seed_letter': seed,
            'maxlen': maxlen,
            'total_names': 1,
            'temperature': 0.5            
        })
        .then(response => {
            this.setState({loading: false});
            // console.log(response);
            if (response) {
                let name = response.data.name;
                name = name.charAt(0).toUpperCase() + name.slice(1);
                const randIndex = Math.floor(Math.random() * this.props.icons.length - 1 ) + 1;
                const randIcon = this.props.icons[randIndex];
        
                this.setState({
                    generatedName: name,
                    characterIcon: randIcon

                });
                // Notify listeners
                this.props.onNameGenerated('Ironman');
            }
        })
        .catch(err => {
            this.setState({loading: false});
            console.log(`Error loading superhero name: ${err}`)
        });
    }

    render() {
        // const randIndex = Math.floor(Math.random() * this.props.icons.length - 1 ) + 1;
        // console.log(`Random index ${randIndex}`);
        // const nameIcon = this.props.icons[randIndex];
        const {loading, characterIcon} = this.state;
        
        return (
            <>
            <Card title='Try it yourself' style={{marginLeft: 10, background: "#ebeaee"}}>
                <Row>                    
                    <Col span={24}>
                        Generate a new name by typing the starting letter and the maximum length.                        
                    </Col>
                    <Col span={24}>
                    <small>(If you leave it blank, I will pick a random letter for you)</small>
                    </Col>
                </Row>
                <Row>                    
                    <Col span={24}>
                        <div>
                            <Row gutter={10}>
                                <Col span={6}>
                                    <Input placeholder="letter" onChange={this.onSeedLetterChange} value={this.state.seedLetter} />
                                </Col>
                                <Col span={6}>
                                    {/* <Input placeholder="length" onChange={this.onMaxLenChange} value={this.state.maxLenValue} /> */}
                                    <InputNumber
                                        min={20}
                                        max={400}
                                        style={{ marginLeft: 16 }}
                                        value={this.state.maxLenValue}
                                        onChange={this.onMaxLenChange}
                                    />

                                </Col>
                                <Col span={6}>
                                    <Button onClick={this.generateNewName.bind(this)} loading={loading}>Generate new name</Button>
                                </Col>
                                {/* <Col span={6}>
                                of max length
                                </Col> */}
                                {/* <Col span={6}>
                                    <Slider min={4} max={26} onChange={this.onMaxLenChange} value={this.state.maxLenValue} />
                                </Col> */}
                                {/* <Col span={6}>
                                    <InputNumber
                                        min={4}
                                        max={26}
                                        style={{ marginLeft: 16 }}
                                        value={this.state.maxLenValue}
                                        onChange={this.onMaxLenChange}
                                    />
                                </Col> */}

                            </Row>
                            <Row gutter={10}>
                                {/* <Col span={6}>
                                Max length
                                </Col> */}
                                {/* <Col span={6}>
                                    <Slider min={4} max={26} onChange={this.onMaxLenChange} value={this.state.maxLenValue} />
                                </Col> */}
                                {/* <Col span={6}>
                                    <InputNumber
                                        min={4}
                                        max={26}
                                        style={{ marginLeft: 16 }}
                                        value={this.state.maxLenValue}
                                        onChange={this.onMaxLenChange}
                                    />
                                </Col> */}
                            </Row>                            
                        </div>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                        <div style={{marginTop: 20}}>
                            <Col span={3}>
                                {/* <img src={nameIcon} /> */}
                                <Img fluid={characterIcon} />   
                            </Col>
                            <Col span={20}>
                                <h2 style={{letterSpacing: 3, fontSize: 'xx-large'}}>{this.state.generatedName}</h2>
                            </Col>                                
                        </div>
                    </Col>
                </Row>
            </Card>
            </>
        )
    }
}

NameGenEntryForm.propTypes = {
    onNameGenerated: PropTypes.func.isRequired
}

export default class SuperHeroes extends Component {
    constructor(props) {
        super(props)
    }

    handleNameGenerated = (value) => {
        console.log(`New name generated: ${value}`);
    }

    render() {

        return (
            <div>
                <h2>Name generator</h2>
                <Row gutter={16}>
                    <Col span={24}>
                        <div>
                        <Tag>Amre</Tag>
                        <Tag>Bar bov</Tag>
                        <Tag>Calios M</Tag>
                        <Tag>Dar</Tag>
                        <Tag>Emy Hyan</Tag>
                        <Tag>Feltoe</Tag>
                        <Tag>Gerar</Tag>
                        <Tag>Hoana</Tag>
                        The neural network trained on the fictional characters from Marvel and DC universe came up with these names.                         
                        The network learned to generate the names by going through each character in Marvel and DC and building a language model to predict the next letter given a prior letter.
                        The original dataset is available <a href="https://github.com/fivethirtyeight/data/tree/master/comic-characters">here</a>.
                    </div>                        
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24} className="gutter-row" >
                        <p>
                            Data Preparation: Each entry in the dataset was converted to the training data by breaking every name into an array of letters.
                            Then, feeding the network one letter at a time and asking it to predict the next.                            
                            An example of first few entries in the dataset would look like this:
                        </p>
                        <table>
                            <thead>
                            <tr>
                                <td>
                                    <strong>Input</strong>
                                </td>
                                <td>
                                    <strong>Output</strong>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                ['I', 'R', 'O' ,'N', 'M', 'A', 'N']
                                </td>
                                <td>
                                ['R', 'O' ,'N', 'M', 'A', 'N', '.']
                                </td>
                            </tr>
                            <tr>
                                <td>
                                ['W', 'O', 'N' ,'D', 'E', 'R', 'W', 'O', 'M', 'A', 'N']
                                </td>
                                <td>
                                ['O', 'N' ,'D', 'E', 'R', 'W', 'O', 'M', 'A', 'N', '.']
                                </td>
                            </tr>
                            <tr>
                                <td>
                                ['F', 'L', 'A' ,'S', 'H']
                                </td>
                                <td>
                                ['L', 'A' ,'S', 'H', '.']
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col span={24} className="gutter-row" style={{marginTop: 10}}>
                        <NameGenEntryForm onNameGenerated={this.handleNameGenerated} icons={this.props.icons}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}> 
                    </Col>
                </Row>
                <Collapse bordered={false}>
                {/* <Collapse bordered={false} defaultActiveKey={['1']}> */}
                    <Panel header="Sample names generated by the network (cherry picked)" key="1" style={customPanelStyle} >
                        <Tag>Amre</Tag>
                        <Tag>Bar bov</Tag>
                        <Tag>Calios M</Tag>
                        <Tag>Dar</Tag>
                        <Tag>Emy Hyan</Tag>
                        <Tag>Feltoe</Tag>
                        <Tag>Gerar</Tag>
                        <Tag>Hoana</Tag>
                        <Tag>Ilfia</Tag>
                        <Tag>Jamaar</Tag>
                        <Tag>Karaci Rorto</Tag>
                        <Tag>Ludche</Tag>
                        <Tag>Monam</Tag>
                        <Tag>Namut</Tag>
                        <Tag>Odord</Tag>
                        <Tag>Prarshak</Tag>
                        <Tag>Qiny</Tag>
                        <Tag>Rasa Pilakleli</Tag>
                        <Tag>Stiren</Tag>
                        <Tag>Uoson</Tag>
                        <Tag>Var</Tag>
                        <Tag>Wag Ky Man</Tag>
                        <Tag>Xedaissa</Tag>
                        <Tag>Yo</Tag>
                        <Tag>Zea</Tag>
                    </Panel>
                    {/* <Panel header="Sampling at various epochs" key="2" style={customPanelStyle} >
                        {text}
                    </Panel> */}
                </Collapse>
            </div>
        )
    }
}