import React from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'

export default function  PostPublished(props) {
  const { frontmatter } = props
  console.log(`frontmatter`, frontmatter)

    // const frontmatter = this
    if (!frontmatter) {
      return null
    }


    let published
    if (frontmatter.props.updated === null) {
      published = (
        <div className="date-published">
          <p>
            <em>
              published {moment(frontmatter.props.written).format(`D MMM YYYY`)}
            </em>
          </p>
        </div>
      )
    } else {
      published = (
        <div className="date-published">
          <p>
            <em>
              originally published{` `}
              {moment(frontmatter.props.written).format(`D MMM YYYY`)} and
              updated{` `}
              {moment(frontmatter.props.updated).format(`D MMM YYYY`)}
            </em>
          </p>
        </div>
      )
    }

    return <div className="container content">{published}</div>
  
}

// export default PostPublished
