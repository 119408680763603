import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import { Row, Col } from 'antd';
import { Button, Card, Collapse, Tag } from 'antd';
import { Input, Slider, InputNumber } from 'antd';

const { TextArea} = Input;

// import Card from 'material-ui/core/Card';

const Panel = Collapse.Panel;
const text = (
    <p style={{ paddingLeft: 24 }}>
        A dog is a type of domesticated animal.
        Known for its loyalty and faithfulness,
        it can be found as a welcome guest in many households across the world.
    </p>
);
const jokerPanelStyle = {
    background: '#ebeaee',
    borderRadius: 4,
    marginTop: 4,    
    marginBottom: 2,
    border: 0,
    overflow: 'hidden',
  };

class JokesGenEntryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            maxLenValue: 20,
            seedLetter: '',
            generatedName: ''
        }
    }

    onMaxLenChange = (value) => {
        this.setState({
            maxLenValue: value,
        })
    }

    onSeedLetterChange = (e) => {
        this.setState({
            seedLetter: e.target.value
        })
    }

    clearJoke = () => {
        this.setState({
            seedLetter: ''
        })
    }

    generateNewJoke() {
        console.log('Generate new name');
        let API = axios.create({
            baseURL: 'https://api.sprinkleai.com'
        });
    
        try {

            let seed = this.state.seedLetter.length > 0  ? this.state.seedLetter : 'what do you ';
            this.setState({
                seedLetter: seed,
            })
            this.setState({loading: true});
            let maxlen = this.state.maxLenValue;
            API.post('/namegen/joke', {
                'model': 'jokerv2',
                'seed_letter': seed,
                'num_words': maxlen,
                'total_names': 1,
                'temperature': 0.5
            },
            {
                crossdomain: true
            }
            ).then(response => {
                this.setState({loading: false});                
                // console.log(response);
                if (response) {
                    let name = response.data.name;
                    // name = name.charAt(0).toUpperCase() + name.slice(1);
                    this.setState({
                        seedLetter: name,
                    });                    
        
                    // this.setState({
                    //     generatedName: name
                    // });
                    // Notify listeners
                }
            })
            .catch(err => {
                this.setState({loading: false});
                console.log(`Error loading superhero name: ${err}`)
            });
    
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const {loading, seedLetter} = this.state;
        console.log(seedLetter);

        return (            
            <Card title='Try it yourself' style={{marginRight: 10, background: "#ebeaee"}}>
                <Row>                                        
                    <Col span={24}>
                        <div>
                            <Row>
                            <Col span={4}>
                                    <Button onClick={this.generateNewJoke.bind(this)} loading={loading}>Generate joke</Button>
                                </Col>
                                <Col span={4}>
                                    <Button onClick={this.clearJoke.bind(this)}>Clear</Button>
                                </Col>
                            </Row>
                            <Row gutter={10} style={{marginTop: '5px'}}>
                                <Col span={24}>
                                    <TextArea  rows={5} placeholder="Add starting word" onChange={this.onSeedLetterChange} value={seedLetter} />
                                </Col>
                            </Row>
                            <Row gutter={10}>
                            </Row>    
                            <Row>
                                <Collapse bordered={false} style={{background: "#ebeaee"}}>
                                <Panel header="Optional control" key="1" style = {jokerPanelStyle} >
                                <Col span={12}>
                            Max length of generated content
                            </Col>
                            <Col span={4}>
                                    <Slider min={2} max={40} onChange={this.onMaxLenChange} value={this.state.maxLenValue} />
                                </Col>                                
                                <Col span={2}>
                                    {/* <p>
                                        {this.state.maxLenValue}
                                    </p> */}
                                    <InputNumber
                                        min={20}
                                        max={400}
                                        style={{ marginLeft: 16 }}
                                        value={this.state.maxLenValue}
                                        onChange={this.onMaxLenChange}
                                    />
                                </Col>
                                </Panel>
                                </Collapse>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default class CleanJoker extends Component {
    constructor(props) {
        super(props)
    }

    handleNameGenerated = (value) => {
        console.log(`New name generated: ${value}`);
    }

    render() {

        return (
            <div>
                <h2>Joker</h2>
                <Row gutter={10}>
                <Col span={24}>
                <p>
                        This model was trained on a corpus of jokes from <a href="https://www.kaggle.com/abhinavmoudgil95/short-jokes">Kaggle</a>. 
                        I found the first iteration of joker to be nasty and largely NSFW, this was due to the large number of NSFW jokes in the dataset.                        
                    </p>
                    <p>
                        So, I decided to clean it by dropping NSFW jokes but reviewing 231,657 jokes manually wasn't too motivating.
                        Luckily, there is already a list of bad words available on the <a href="https://www.cs.cmu.edu/~biglou/resources/bad-words.txt">CMU website</a> so I used that to drop all jokes that contained words from the list.
                        After the cleanup, we 170,959 jokes left for the training.
                        The second iteration of Joker(CleanJoker) looked clean enough to host online. 
                    </p>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <JokesGenEntryForm />
                    </Col>
                    <Col span={24}>
                <p>
                    A few handpicked ones that were generated by the Joker. 
                    Whilst the generated text doesn't make sense in its entirety, it does seem funny in bits.
                </p>
                <p style={{letterSpacing:2, fontSize:'large'}}>
                    1. What do you do when you can be a little bear that says "I can start a bar"
                </p>
                <p style={{letterSpacing:2, fontSize:'large'}}>
                    2. Did you hear about pizza needed its packers!
                </p>
                <p style={{letterSpacing:2, fontSize:'large'}}>
                    3. What did the chicken creepling been drinking questions. The butchers to eat it.
                </p>
                </Col>
                </Row>
            </div>
        )
    }
}


// export default class CleanJoker extends Component {
//     constructor(props) {
//         super(props)
//     }

//     render() {
//         return (
//             <div>
//                 <h2>Joker</h2> 
//                 <p>
//                 This model was trained on a corpus of jokes from <a href="https://www.kaggle.com/abhinavmoudgil95/short-jokes">Kaggle</a>. 
//                 I found the first iteration of joker to be nasty and largely NSFW, this was due to the large number of NSFW jokes in the dataset.
//                 It learned mostly the bad words and would spount nasty stuff.
//                 </p>
//                 <p>
//                 I decided to clean it by dropping NSFW jokes but reviewing 231,657 jokes manually wasn't going to motivate anyone (unless hosted on MTurk).
//                 Luckily, there is already a list of bad words available on CMU <a href="https://www.cs.cmu.edu/~biglou/resources/bad-words.txt">CMU website</a> so all the jokes that contained bad words were dropped from the list.
//                 The second iteration of Joker(CleanJoker) looked clean enough to share the results. 

//                 There are a few hand picked jokes generated by CleanJoker.
                
//                 [Hand Picked Jokes]

//                 You can give it a go by add a few words and let it complete the joke for you.

//                 [Clean Joker APP]

//                 </p>
//             </div>
//         )
//     }
// }
