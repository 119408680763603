import React from "react"
import { graphql } from "gatsby"
import { Divider } from 'antd';

import BlogPostChrome from "../../components/BlogPostChrome"
import { findDOMNode } from "react-dom"
import SuperHeroes from './superheroes'
import CleanJoker from './joker'
import TedSpeaker from './tedspeaker'
import Img from 'gatsby-image'
import Poster from './poster.png'
import {Row, Col } from 'antd';
import Layout from './../../components/Layouts';

export const frontmatter = {
    title: `Neural Text Generation`,
    written: `2018-06-30`,
    updated: `2018-07-10`,
    layoutType: `post`,
    contentType: "blog",        
    path: `/generativetext/`,
    category: `Deep learning`,
    image: './poster.png',
    description: `Can neural networks learn to write stories ? Can they be taught to crack jokes or write articles ?`,
}

class GenerativeTextBase extends React.Component {
    constructor(props) {
      super(props)
      // console.log('GenerativeTextBase:', Poster);
    }
  
    render() {    
      // console.log(this.props.data)  
      let data = this.props.data.markdownRemark
      // let html = data.html
      let file = this.props.data.file    
      const flow300 = this.props.data.sunset.childImageSharp.fluid

      console.log(this.props.data);
      console.log(typeof(this.props.data));
      const superheroIcons = Object.keys(this.props.data)
        .filter(item => item.endsWith('_icon'))
        .map(item => {
          return this.props.data[item].childImageSharp.fluid
        });

      // console.log(superheroIcons);

      // const kap_icon = this.props.data.ironman_icon.childImageSharp.resolutions
      // const dr_strange = this.props.data.dr_strange.childImageSharp.resolutions
      // const spidey = this.props.data.spidey.childImageSharp.resolutions
      // const thanos = this.props.data.thanos.childImageSharp.resolutions
      // const kul = this.props.data.kul.childImageSharp.resolutions
      // const warmach = this.props.data.warmach.childImageSharp.resolutions
      // const ironman = this.props.data.ironman.childImageSharp.resolutions

      // const superheroIcons = [
      //   kap_icon.src,
      //   dr_strange.src,
      //   spidey.src,
      //   thanos.src,
      //   kul.src,
      //   warmach.src,
      //   ironman.src,
      // ]

      return (
        <Layout data={this.props.data} location={this.props.location}>
        <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
        <h1 style={{ textAlign: "center"}}>
          Neural Text Generation
        </h1>
        <p
          className="header-subtitle"
          style={{ marginTop: 20, marginBottom: 10 }}
        >          
          Desktop Version | 30 Jun, 2018
        </p>
        
        <article>
            <p>
            This post explores character-based language modeling using Long Short Term Memory (LSTM) networks. The network is 
            trained on a corpus of choice such as movie scripts, novels, etc., to learn the latent space. The latent space 
            is sampled to generate new text. The network is trained by passing a sequence of characters, one 
            at a time, and asking the network to predict the next character. Initially, the network predictions are 
            random, but it learns to correct its mistakes and gets better at generating words and even sentences that appear 
            meaningful. The network also learns the dataset-specific characteristics such as opening and closing the brackets, 
            adding punctuations and hyphens, etc.
          </p>
          <Img fluid={flow300} />   
          <p>            
            The rest of the article explores network trained on various dataset.
          </p>       
         </article>
         {/* <Divider> Name Generator </Divider> */}
         <Divider> 
           {/* <p style={{fontSize: 'x-large'}}>
            Name Generator
          </p> */}
         </Divider>
         <SuperHeroes icons={superheroIcons}/>
         <Divider/>
         <CleanJoker />
         <Divider />
         <TedSpeaker />
         <Divider />
         <h2> Conclusion</h2>
         <p>
         The models above had to learn the characters/letters, put them together to form words and construct sentences. 
         It may appear that these models understand what they are generating/creating but that is not the case.
         What they have learned is the statistical model of the language (dataset) and they are merely sampling from it.
         </p>
         <p>
         On their own, the models may not be of much use but they could serve as tools to augment writers. 
         Something like a smart editor that can autocomplete the sentence or show possible options (sampled from the latent space).
         The models could serve as autocomplete plugins (e.g genre based language models, models trained on specific datasets such as speeches, movie scripts etc).
         There could possibly be a marketplace/plugin store to host pre-trained language models.
        </p>
        
        <p>
          <i>PS: For those "forcing" their bots to read/write/watch, just ask them nicely and they'll oblige else they might get back at you with a powerful tensor exception</i> &#x1f61b;
        </p>
        <Divider />
        <Row>
                        <Col span={4} className="references-header">
                            References:
          </Col>
                        <Col span={20} className="references-text">
                            <ol>
                                <li>
                                The Unreasonable Effectiveness of Recurrent Neural Networks by Andrej Karpathy
                                &nbsp;[<a href="http://karpathy.github.io/2015/05/21/rnn-effectiveness/">Link</a>]
                            </li>
                            </ol>
                        </Col>
                    </Row>        
        <Row>
          <Col span={4}></Col>
          <Col span={20}></Col>
        </Row>
        <div>
          <div>Icons created by <a href="http://arjun.katnoria.com" title="Arjun">Arjun</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a></div>
        </div>
        </BlogPostChrome>
        </Layout>
      )
    }
  }
  
  export default GenerativeTextBase
  
  const styles = {}

  styles.row = {
    display: `flex`,
    flexWrap: `wrap`,
    margin: `8px -4px 1rem`,
  }

  // We want to keep this component mostly about the code
  //  so we write our explanation with markdown and manually pull it in here.
  //  Within the config, we loop all of the markdown and createPages. However,
  //  it will ignore any files appended with an _underscore. We can still manually
  //  query for it here, and get the transformed html though because remark transforms
  //  any markdown based node.
  export const pageQuery = graphql`
    query sentimentanalysisquery($slug: String!) {
      markdownRemark(
        fields: { slug: { eq: "/2018-06-10-generativetext/_gentext/" } }
      ) {
        html
      }
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
      sunset: file(
        relativePath: {regex: "/high_level_flow.png/"}) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      capk2_icon: file(
        relativePath: {regex: "/captain-k2.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 32) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      dr_strange_icon: file(
        relativePath: {regex: "/dr_strange_v1_32px.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 41) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      spidey_icon: file(
        relativePath: {regex: "/spidey_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 36) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      thanos_icon: file(
        relativePath: {regex: "/thanos_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 39) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      kul_icon: file(
        relativePath: {regex: "/kul_obs_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 39) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      warmach_icon: file(
        relativePath: {regex: "/war_mac_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 103) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      ironman_icon: file(
        relativePath: {regex: "/ironman_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 25) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      hb_icon: file(
        relativePath: {regex: "/hb_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 27) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      groot_icon: file(
        relativePath: {regex: "/baby_groot_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      thor_icon: file(
        relativePath: {regex: "/thor_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 34) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      vision_icon: file(
        relativePath: {regex: "/vision_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
      happy_icon: file(
        relativePath: {regex: "/happy_dude_v1_32.png/"}) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 59) {
            ...GatsbyImageSharpFluid
          }
        }
      }         
    }
  `
  