import React from "react"
import { graphql } from "gatsby"
import HelmetBlock from "../HelmetBlock"
import PostPublished from "../PostPublished"
import Img from 'gatsby-image'

class BlogPostChrome extends React.Component {
  state = {
    cover_img : null,
    coverText: ""
  }

  componentDidMount() {
    let frontmatter = this.props.frontmatter;
    if(frontmatter && frontmatter.cover) {      
      const { cover, coverhover, coverText } = frontmatter;
      const cover_img = cover ? cover.childImageSharp : null;
    this.setState({cover_img, coverText});
    }
  }

  updateCoverImage(imageType="cover") {
    console.log(`hover: ${imageType}`)
    let frontmatter = this.props.frontmatter;
    if(frontmatter && frontmatter.cover) {      
      const { cover, coverhover } = frontmatter;
      const cover_img = cover ? cover.childImageSharp : null;
      const coverhover_img = coverhover ? coverhover.childImageSharp : null;
      const img = imageType === "cover" ? cover_img : (coverhover_img||cover_img);
      this.setState({cover_img: img});
    }
  }

  render() {
    let frontmatter = this.props.frontmatter;
    const maxWidth = this.props.maxWidthOverride ?? 800
    const backgroundColor = this.props.backgroundColorOverride ?? 'white'
    let coverImage = <div></div>;
    if(frontmatter && frontmatter.cover) {
      // const { cover, coverhover } = frontmatter;
      // // const cover_img = frontmatter.cover.childImageSharp      
      // const cover_img = cover ? cover.childImageSharp : null;
      // const coverhover_img = coverhover ? coverhover.childImageSharp : null;      
      // console.log(cover_img);

      const { cover_img, coverText } = this.state;
      console.log(`coverText: ${coverText}`);      
      coverImage = (
        <div style={{width: "100%"}} onMouseEnter={() => this.updateCoverImage('hover')} onMouseLeave={() => this.updateCoverImage('cover')}>
          <figure>            
            {cover_img && <Img sizes={cover_img.fluid}/>}
            {coverText && <p style={{fontSize: 12, textAlign: "center"}}>{coverText}</p>}
          </figure>
        </div>
      )        
    }

    return (
      <div className="BlogPostChrome" style={{backgroundColor}}>
        {/* <HelmetBlock {...frontmatter} /> */}
        {coverImage}
        <div className="content">
          <div className="section">
            <div className="container content"  style={{maxWidth, margin: "0 auto"}}>{this.props.children}</div>
          </div>
        </div>
        <PostPublished {...frontmatter} />
      </div>
    )
  }
}

export default BlogPostChrome

export const blogPostFragment = graphql`
  fragment MarkdownBlogPost_frontmatter on MarkdownRemark {
    frontmatter {
      title
      path
      layoutType
      contentType
      written
      updated
      category
      description
    }
  }

  fragment JSBlogPost_data on JavascriptFrontmatter {
    frontmatter {
      title
      path
      layoutType
      contentType
      written
      updated
      category
      description
      cover {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }              
      }
      coverText
      coverhover {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }              
      }      
    }
  }
`
